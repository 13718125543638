




















































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Tooltips extends Vue {
  /**
   * Component mounted. Import after mounting to
   * have DOM elements available for jQuery.
   */
  mounted(): void {
    // @ts-ignore
    // eslint-disable-next-line babel/no-unused-expressions
    import('@/../vendor/dashkit/src/assets/js/tooltip');
  }
}
